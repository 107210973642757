.App {
  /* PC and landscape tablet*/
  /* @media (min-width: 768px) { */
    .hero {
      background-color: powderblue;
      background: url('backtitle.jpg') no-repeat center center fixed; 
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding: 25px 0px 50px;
      height: 600px;
    }
  /* } */

  .hero p {
    padding: 50px
  }

  /* Tablet -portrait */
  @media (max-width: 768px) {
    .hero {
    background: url('backtitle-mob.jpg') no-repeat center top fixed; 
    background-size: 100%;
    padding: 0px 0px 0px;
    height: 700px;
    }
    .hero p {
      padding: 50px
    }

  }


  /* Phone */
  @media (max-width: 400px) {
    .hero {
    background: url('backtitle-mob.jpg') no-repeat center top fixed; 
    background-size: 100%;
    padding: 0px 0px 0px;
    height: 700px;
    }
    .hero p {
      color: white;
      padding: 80px 50px 0px
    }

  }

  .section-odd {
    background-color: white;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .section-even {
    background-color: lightgray;
    padding-top: 25px;
    padding-bottom: 25px;
  }

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
